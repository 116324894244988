@import url('https://fonts.googleapis.com/css2?family=Fraunces:ital,opsz,wght@0,9..144,100..900;1,9..144,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}


@layer base {
  * {
    @apply m-0 p-0 box-border;
  }

  ::-webkit-scrollbar {
    width: 18px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #dd0c40;
    /* Tailwind's blue-500 */
    border-radius: 16px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #e62867;
    /* Tailwind's blue-600 */
  }

  /* For Firefox */
  * {
    scrollbar-width:thin;
    scrollbar-color: #dabeb5 #f1f1f1;
  }

  body {
    @apply m-0 font-fraunces-slab font-work-sans-slab antialiased overflow-x-hidden;
  }
}

@layer utilities {

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .font-fraunces-slab {
    font-family: "Fraunces", serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-variation-settings:
      "SOFT" 0,
      "WONK" 0;
  }

  .font-work-sans-slab {
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
  }
}